@import './var.module.scss';

.title {
    display: block;
    h3 {
        font-size: $FontsizeXL;
        font-weight: $fontWeightMed;
        line-height: 26px;
        margin: 0;
        border-bottom: 2px solid $colorGrey;
        padding-bottom: 16px;
    }
}
@media only screen and (max-width: 992px) {
    .title {
        h3 {
            padding-bottom: 10px;
        }
        margin-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
