.afa {
    &::after {
        content: attr(data-code);
        height: fit-content;
        border: 1px solid;
        background-color: var(--data-code-bg-color-var);
        /* margin: 17px; */
        margin-top: 10px;
        margin-left: -10px;
    }

    .afa {
        &::after {
            content: attr(data-code);
            height: fit-content;
            border: 1px solid;
            background-color: var(--data-code-bg-color-var);
            /* margin: 17px; */
            margin-top: 10px;
            margin-left: -10px;
        }
    }

    .headerLogoImage {
        position: fixed;
        top: 0;
        left: 0;
        // background-color: white;
    }
}
