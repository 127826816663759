@import './var.module.scss';

.createFormButton {
    min-width: 130px;
    min-height: 62px;
    width: 100%;
    cursor: pointer;
    background-color: transparent;
    color: black !important;
    border: 0;
    box-shadow: $shadow;
    padding: 18px 30px;
    line-height: 26px;
    font-size: $FontsizeXL !important;
    border-radius: 5px;
    margin-bottom: 16px;
    font-weight: $fontWeightMed !important;
    &:hover {
        background-color: #f2f2f2;
    }
    .icon {
        display: none;
    }
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
            background-color: transparent;
        }
    }
}
