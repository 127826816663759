.leftSidebar {
  position: fixed;
  z-index: 999;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  height: 100%;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  transition: margin 2s ease-in;
  li {
    margin: 10px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  // transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  // }),
}

.sideBarIcon {
  background-image: url('../img/presentation-alt.svg');
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
  position: absolute;
}

.moduleItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  width: 100%;
  height: 60px;
}

.module {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  // background-color: black;
}

.text {
  font-size: 0.75rem;
  line-height: 16px;
  width: 50px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #393939;
  position: absolute;
  bottom: 10px;
  // right:50;
  // transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
