@import './var.module.scss';

.commonAvatar {
  border-radius: 8px;
}

.mediumAvatar {
  width: 25px;
  height: 25px;
  font-size: 1.2rem;
}

.menuCaption {
  font-size: 1rem !important;
  font-weight: bold !important;
  padding: 0px !important;
}

.subMenuCaption {
  font-size: 0.75rem !important;
  font-weight: 500;
  text-transform: capitalize;
}

/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.svgIcon {
  width: 32px !important;
  height: 32px !important;
  padding: unset !important;

  &[data-disabled='true'] {
    filter: invert(100%) sepia(8%) saturate(425%) hue-rotate(219deg) brightness(117%) contrast(87%);
  }

  img {
    width: 16px;
    height: 16px;
  }
}


.fillBox {
  width: stretch;
  width: -webkit-fill-available;
  width: -moz-available;
}