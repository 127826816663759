@import './var.module.scss';

.summaryTable {
    padding: 1rem;
    margin-bottom: 1rem;

    thead {
        th {
            color: $fontGrey;
            border: 1px solid rgba(224, 224, 224, 1);
        }
    }

    tbody {
        td {
            color: $fontGrey;
            border: 1px solid rgba(224, 224, 224, 1);
        }
    }
}
